// wraps a Phoenix Websocket connection
import { Socket } from 'phoenix';
import swal from 'sweetalert';
import { apiBase, apiPrefix } from '../config/apiUrl';
import WebSocketListener from './WebsocketListener';

const suffix = apiPrefix.replace('http', '');
const url = `ws${suffix}//${apiBase}/socket`;

class Connection {
  constructor(sessionStore) {
    console.log('connecting...');
    this.onError = this.onError.bind(this);
    this.sessionStore = sessionStore;
    this._socket = new Socket(url, { params: { token: sessionStore.token } });
    this._socket.onError(this.onError);
    this.connect();

    this.networkWarning = false;

    this.disconnect = this.disconnect.bind(this);
    this.isConnected = this.isConnected.bind(this);
  }

  connect() {
    this._socket.connect();
    const { currentUser, organization } = this.sessionStore;
    const channel = this._socket.channel(`MESSAGE:${currentUser.id}`);
    if (organization && organization.id) {
      const trackingChannel = this._socket.channel(
        `ORGANIZATION:${organization.id}`,
      );
      this.listener = new WebSocketListener(
        channel,
        trackingChannel,
        currentUser.id,
      );
    } else {
      this.listener = new WebSocketListener(channel, null);
    }
    console.log('socket connected');
  }

  onError(error) {
    console.error('WebSocket error:', error);
    if (
      !this.sessionStore.currentUser ||
      !this.sessionStore.currentUser.token
    ) {
      this.disconnect();
    } else if (!this.networkWarning) {
      let content = document.createElement('div');
      content.innerHTML = `You've been disconnected! The best way to quickly reconnect is to <strong class="font-130">REFRESH</strong> your browser.`;

      swal({
        content,
        title: 'Network Disconnected',
        icon: 'warning',
      });
      this.networkWarning = true;
    }
  }

  disconnect() {
    this.listener._channel.leave();
    this._socket.remove(this.listener._channel);
    if (this.listener._trackingChannel) {
      this.listener._trackingChannel.leave();
      this._socket.remove(this.listener._trackingChannel);
    }
    this._socket.disconnect();
    console.log('socket disconnected');
  }

  connectUserMessages(store) {
    this.userMessageStore = store;
    this.userMessageChannel = this._socket.channel(
      `user_messages:${this.sessionStore.currentUser.id}`,
    );
    this.userMessageChannel.on('new_message', message =>
      this.userMessageStore.add(message),
    );
    this.userMessageChannel.join().receive('ok', ([targets, messages]) => {
      console.log('user_messages channel joined');
      this.userMessageStore.addMany(messages);
    });
  }

  sendUserMessage(payload) {
    this.userMessageChannel
      .push('new_message', payload)
      .receive('ok', message => this.userMessageStore.add(message));
  }

  isConnected() {
    return this._socket.isConnected();
  }
}

export default Connection;
