import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressCard,
  faArchive,
  faArrowCircleDown,
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowCircleUp,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faAward,
  faBars,
  faBell,
  faBolt,
  faBook,
  faBookReader,
  faBriefcase,
  faCalendarAlt,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCalculator,
  faChalkboardTeacher,
  faChartLine,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChild,
  faCircle,
  faClipboard,
  faClipboardCheck,
  faClock,
  faClone,
  faCog,
  faComments,
  faDoorClosed,
  faDoorOpen,
  faEdit,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFile,
  faFlask,
  faFrown,
  faGlobeAmericas,
  faHistory,
  faHome,
  faInfoCircle,
  faLaptop,
  faLaptopCode,
  faLock,
  faLockOpen,
  faMapMarkerAlt,
  faMinus,
  faPause,
  faPlay,
  faPlus,
  faPlusCircle,
  faPowerOff,
  faQuestionCircle,
  faQuoteLeft,
  faQuoteRight,
  faSave,
  faSchool,
  faSearch,
  faShareAlt,
  faSmile,
  faStar,
  faStepForward,
  faStop,
  faStopwatch,
  faTasks,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faTrophy,
  faUndo,
  faUser,
  faUserCog,
  faUserEdit,
  faUsers,
  faBuilding,
  faEnvelopeSquare,
  faUserFriends,
  faWindowClose,
  faSquare
} from '@fortawesome/free-solid-svg-icons';
import {
  faBell as farBell,
  faQuestionCircle as farQuestionCircle,
  faCalendar,
  faPlayCircle,
  faImage,
} from '@fortawesome/free-regular-svg-icons';
import {
  faArrowSquareUp,
  faBallotCheck,
  faBooks,
  faBooksMedical,
  faCalculatorAlt,
  faCommentAltSmile,
  faCommentAltExclamation,
  faCommentAltDots,
  faCommentAltCheck,
  faCommentAltLines,
  faCommentAltPlus,
  faCommentLines,
  faExternalLink,
  faInfoSquare,
  faList,
  faPencil,
  faPollPeople,
  faSignOut,
  faStars,
  faTachometerFast,
  faTally,
  faTrophyAlt,
  faUsersClass,
  faBrowser
} from '@fortawesome/pro-solid-svg-icons';
import {
  faAbacus as falAbacus,
  faCircleNotch as falCircleNotch,
  faLongArrowDown as falLongArrowDown,
  faThumbsDown as falThumbsDown,
  faThumbsUp as falThumbsUp
} from '@fortawesome/pro-light-svg-icons';
import {
  faChalkboardTeacher as farChalkboardTeacher,
  faChevronDown as farChevronDown,
  faChevronUp as farChevronUp,
  faChevronRight as farChevronRight,
  faTachometerFastest as farTachometerFastest,
  faUserChart as farUserChart,
  faUserCog as farUserCog,
  faStore as farStore,
  faFileCertificate as farFileCertificate,
  faCommentAlt as farCommentAlt
} from '@fortawesome/pro-regular-svg-icons';
import { faSquare as farSquare } from '@fortawesome/pro-regular-svg-icons';
import {
  faApple,
  faTwitter,
  faFacebook,
  faInstagram,
  faCcVisa,
  faCcMastercard,
  faCcDiscover
} from '@fortawesome/free-brands-svg-icons';

// Add icons to the library
const registerFaIcons = () => {
  library.add(
    faAddressCard,
    faApple,
    faArchive,
    faArrowCircleDown,
    faArrowCircleLeft,
    faArrowCircleRight,
    faArrowCircleUp,
    faArrowUp,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowSquareUp,
    faAward,
    faBallotCheck,
    faBars,
    faBell,
    faBolt,
    faBook,
    faBookReader,
    faBooks,
    faBooksMedical,
    faBriefcase,
    faCaretDown,
    faCaretRight,
    faCaretUp,
    faCalculator,
    faCalculatorAlt,
    faCalendarAlt,
    faCcDiscover,
    faCcMastercard,
    faCcVisa,
    faChalkboardTeacher,
    faChartLine,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChevronCircleLeft,
    faChevronCircleRight,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChild,
    faCircle,
    faClipboard,
    faClipboardCheck,
    faClock,
    faClone,
    faCog,
    faCommentAltSmile,
    faCommentAltExclamation,
    faCommentAltCheck,
    faCommentAltDots,
    faCommentAltLines,
    faCommentAltPlus,
    faCommentLines,
    faComments,
    faDoorClosed,
    faDoorOpen,
    faEdit,
    faExclamationCircle,
    faExclamationTriangle,
    faExternalLink,
    faEye,
    faEyeSlash,
    faFile,
    faFlask,
    faFrown,
    faGlobeAmericas,
    faHistory,
    faHome,
    faInfoCircle,
    faInfoSquare,
    faLaptop,
    faLaptopCode,
    faList,
    faLock,
    faLockOpen,
    falLongArrowDown,
    faMapMarkerAlt,
    faMinus,
    faPause,
    faPencil,
    faPlay,
    faPlus,
    faPlusCircle,
    faPollPeople,
    faPowerOff,
    faQuestionCircle,
    faQuoteLeft,
    faQuoteRight,
    faSave,
    faSchool,
    faSearch,
    faShareAlt,
    faSignOut,
    faSmile,
    faStar,
    faStars,
    faStepForward,
    faStop,
    faStopwatch,
    faTachometerFast,
    faTally,
    faTasks,
    faThumbsDown,
    faThumbsUp,
    faTimes,
    faTimesCircle,
    faTrash,
    faTrashAlt,
    faTrophy,
    faTrophyAlt,
    faTwitter,
    faFacebook,
    faInstagram,
    faUndo,
    faUser,
    faUserCog,
    faUserEdit,
    faUsers,
    faUsersClass,
    falAbacus,
    falThumbsDown,
    falThumbsUp,
    falCircleNotch,
    farBell,
    farChalkboardTeacher,
    farChevronDown,
    farChevronRight,
    farChevronUp,
    farQuestionCircle,
    farSquare,
    farStore,
    farTachometerFastest,
    farUserChart,
    farUserCog,
    faBuilding,
    faCalendar,
    faEnvelopeSquare,
    faUserFriends,
    faBrowser,
    faWindowClose,
    farCommentAlt,
    farFileCertificate,
    faSquare,
    faPlayCircle,
    faImage
  );
};

export default registerFaIcons;
