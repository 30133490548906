import { observable, action, computed, decorate } from "mobx";
import _ from "lodash";
import Moment from "moment";
import { extendMoment } from "moment-range";
import apiActions from "api/actions";
import { getTimezone, getUTCOffset } from "utils/date-utils";

const moment = extendMoment(Moment);

function getInitialQueryData() {
  const start = moment().add(-1, "months");
  const end = moment().add(2, "months");
  const range = moment.range(start, end);

  return Array.from(range.by("month")).map((month) => ({
    params: { year: month.format("YYYY"), month: month.format("MM") },
    key: month.format("YYYY-MM"),
  }));
}

export class UlcRawAvailability {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.learning_center_id = data.learning_center_id;
    this.event_type_id = data.event_type_id;
    this.day = data.day; // Deprecated
    this.date = data.date;
    this.start_time = data.start_time;
    this.end_time = data.end_time;
    this.until = data.until;
    this.location = data.location;
    this.online = data.online;
    this.at_home = data.at_home;
    this.frequency = data.frequency;
    this.anchor_timezone = data.anchor_timezone;
    this.anchor_offset = data.anchor_offset;
    this.timeslots = data.timeslots;
  }

  get editableParams() {
    return {
      id: this.id,
      learning_center_id: this.learning_center_id,
      day: this.day,
      date: this.date,
      start_time: this.start_time,
      end_time: this.end_time,
      until: this.until,
      location: this.location,
      online: this.online,
      at_home: this.at_home,
      frequency: this.frequency,
      anchor_timezone: this.anchor_timezone,
      anchor_offset: this.anchor_offset,
    };
  }

  get isRecurring() {
    return this.frequency && this.until;
  }

  get localStart() {
    return moment
      .utc(`${this.date} ${this.start_time}`, "YYYY-MM-DD HH:mm:ss")
      .local();
  }

  get localEnd() {
    const utcStart = moment.utc(
      `${this.date} ${this.start_time}`,
      "YYYY-MM-DD HH:mm:ss"
    );
    const naiveUtcEnd = moment.utc(
      `${this.date} ${this.end_time}`,
      "YYYY-MM-DD HH:mm:ss"
    );
    if (utcStart.isAfter(naiveUtcEnd)) {
      // End time is next UTC date, so we need to shift from there.
      return naiveUtcEnd.add(1, "day").local();
    }
    // End time is same UTC date, so it doesn't matter.
    return naiveUtcEnd.local();
  }

  get localUntil() {
    return moment
      .utc(`${this.until} ${this.start_time}`, "YYYY-MM-DD HH:mm:ss")
      .local();
  }

  editDateInRecurrance = (editedParams) => {
    if (this.frequency === "daily") {
      const startDate = moment(this.date);
      const endDate = moment(this.until);
      const range = moment.range(startDate, endDate);
      const promises = _.chain(Array.from(range.by("day")))
        // Enumerate our availability into multiple, per day
        .map((date) => {
          if (date.format("YYYY-MM-DD") === editedParams.date) {
            return {
              ...this.editableParams,
              ...editedParams,
              frequency: null,
              until: null,
              id: undefined,
              active: true,
              anchor_timezone: getTimezone(),
              anchor_offset: getUTCOffset(date),
            };
          } else {
            return {
              ...this.editableParams,
              date: date.format("YYYY-MM-DD"),
              frequency: null,
              until: null,
              id: undefined,
              active: true,
              anchor_timezone: getTimezone(),
              anchor_offset: getUTCOffset(date),
            };
          }
        })
        // Map each new availability into a request
        .map((availabilityParams) =>
          apiActions.post("availabilities", {
            availability: availabilityParams,
          })
        )
        // Tack on the request to deactivate the original recurrance pattern
        .concat([
          apiActions.update("availabilities", {
            id: this.id,
            availability: { id: this.id, active: false },
          }),
        ])
        // execute the chain
        .value();

      return Promise.all(promises);
    } else if (this.frequency === "weekly") {
      const startDate = moment(this.date);
      const endDate = moment(this.until);
      const range = moment.range(startDate, endDate);
      const promises = _.chain(Array.from(range.by("day", { step: 7 })))
        // Enumerate our availability into multiple, per day
        .map((date) => {
          if (date.format("YYYY-MM-DD") === editedParams.date) {
            return {
              ...this.editableParams,
              ...editedParams,
              frequency: null,
              until: null,
              id: undefined,
              active: true,
              anchor_timezone: getTimezone(),
              anchor_offset: getUTCOffset(date),
            };
          } else {
            return {
              ...this.editableParams,
              date: date.format("YYYY-MM-DD"),
              frequency: null,
              until: null,
              id: undefined,
              active: true,
              anchor_timezone: getTimezone(),
              anchor_offset: getUTCOffset(date),
            };
          }
        })
        // Map each new availability into a request
        .map((availabilityParams) =>
          apiActions.post("availabilities", {
            availability: availabilityParams,
          })
        )
        // Tack on the request to deactivate the original recurrance pattern
        .concat([
          apiActions.update("availabilities", {
            id: this.id,
            availability: { id: this.id, active: false },
          }),
        ])
        // execute the chain
        .value();

      return Promise.all(promises);
    }
  };

  removeDateInRecurrance = (dateToRemove) => {
    if (this.frequency === "daily") {
      const startDate = moment(this.date);
      const endDate = moment(this.until);
      const range = moment.range(startDate, endDate);
      const promises = _.chain(Array.from(range.by("day")))
        // Remove target date first
        .reject((date) => date.format("YYYY-MM-DD") === dateToRemove)
        // Enumerate our availability into multiple, per day
        .map((date) => ({
          ...this.editableParams,
          date: date.format("YYYY-MM-DD"),
          frequency: null,
          until: null,
          id: undefined,
          active: true,
          anchor_timezone: getTimezone(),
          anchor_offset: getUTCOffset(date),
        }))
        // Map each new availability into a request
        .map((availabilityParams) =>
          apiActions.post("availabilities", {
            availability: availabilityParams,
          })
        )
        // Tack on the request to deactivate the original recurrance pattern
        .concat([
          apiActions.update("availabilities", {
            id: this.id,
            availability: { id: this.id, active: false },
          }),
        ])
        // execute the chain
        .value();

      return Promise.all(promises);
    } else if (this.frequency === "weekly") {
      const startDate = moment(this.date);
      const endDate = moment(this.until);
      const range = moment.range(startDate, endDate);
      const promises = _.chain(Array.from(range.by("day", { step: 7 })))
        // Remove target date first
        .reject((date) => date.format("YYYY-MM-DD") === dateToRemove)
        // Enumerate our availability into multiple, per day
        .map((date) => ({
          ...this.editableParams,
          date: date.format("YYYY-MM-DD"),
          frequency: null,
          until: null,
          id: undefined,
          active: true,
          anchor_timezone: getTimezone(),
          anchor_offset: getUTCOffset(date),
        }))
        // Map each new availability into a request
        .map((availabilityParams) =>
          apiActions.post("availabilities", {
            availability: availabilityParams,
          })
        )
        // Tack on the request to deactivate the original recurrance pattern
        .concat([
          apiActions.update("availabilities", {
            id: this.id,
            availability: { id: this.id, active: false },
          }),
        ])
        // execute the chain
        .value();

      return Promise.all(promises);
    }
  };
}

export default class UlcRawAvailabilityStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const availability = new UlcRawAvailability(data, this.root);
    this.data.set(availability.id, availability);
  };

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach((data) => {
      const availability = new UlcRawAvailability(data, this.root);
      merge.set(availability.id, availability);
    });
    this.data.merge(merge);
  };

  remove = (id) => {
    this.data.delete(id);
  };

  find(id) {
    return this.data.get(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  };

  fetchInitial() {
    const queryData = getInitialQueryData();
    const requests = [];

    queryData.forEach(({ params, key }) => {
      if (this.requests.get(key) === undefined) {
        this.requests.set(key, "pending");
        requests.push(
          apiActions
            .v2RawGet("users/me/availabilities", params)
            .then((response) => {
              this.addMany(response.data);
              this.requests.set(key, "complete");
            })
        );
      }
    });

    return Promise.all(requests);
  }

  fetchAll() {
    if (this.requests.get("all") === undefined) {
      this.requests.set("all", "pending");
      return apiActions
        .v2RawGet("users/me/availabilities")
        .then((response) => {
          this.addMany(response.data);
          this.requests.set("all", "complete");
        })
        .then(() => {
          // Check if we need to backfill relevant DST info for availabilities
          const now = moment();
          const shouldBackfillDST = _.some(
            this.all,
            (availability) =>
              (availability.localStart.isAfter(now) ||
                availability.localUntil.isAfter(now)) &&
              (availability.anchor_timezone === null ||
                availability.anchor_offset === null)
          );
          if (shouldBackfillDST) {
            return (
              apiActions
                .v2RawPost("users/me/dst", {
                  timezone: {
                    anchor_timezone: getTimezone(),
                    anchor_offset: getUTCOffset(now),
                  },
                })
                // If so, we should refetch them with the updated data
                .then(this.fetchAll)
            );
          }
          return Promise.resolve();
        });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get("all") !== undefined) {
      this.reset();
      return this.fetchAll();
    } else if (this.fetchInitialCompleted) {
      this.reset();
      return this.fetchInitial();
    }
    return Promise.resolve();
  }

  get fetchInitialCompleted() {
    const queryData = getInitialQueryData();
    return _.every(
      queryData,
      ({ key }) => this.requests.get(key) === "complete"
    );
  }

  get fetchAllCompleted() {
    return this.requests.get("all") === "complete";
  }

  availabilitiesForDate = (date) => {
    date = moment(date);
    const availabilities = this.all.filter((availability) => {
      if (date.isSame(availability.localStart, "day")) {
        return true;
      }
      if (
        availability.frequency === "daily" &&
        date.isSameOrAfter(availability.localStart, "day") &&
        date.isSameOrBefore(availability.localUntil, "day")
      ) {
        return true;
      }
      if (
        availability.frequency === "weekly" &&
        date.isSameOrAfter(availability.localStart, "day") &&
        date.isSameOrBefore(availability.localUntil, "day") &&
        date.day() === availability.localStart.day()
      ) {
        return true;
      }
      return false;
    });
    return availabilities;
  };
}

decorate(UlcRawAvailabilityStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  all: computed,
  reset: action,
  fetchAll: action,
  refetch: action,
  fetchAllCompleted: computed,
});
